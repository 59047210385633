import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import Title from '../components/Title'

const NotFoundPage = ({
  pageContext: { locale },
  data: {
    file: {
      childNotfound: { title, subtitle, button },
    },
  },
}) => (
  <Layout locale={locale}>
    <section className="section hero is-fullheight-with-navbar has-background-white">
      <div className="container">
        <Title title={title} subtitle={subtitle}>
          <Link
            to={locale === 'fr' ? '/' : '/en/'}
            className="button is-primary"
          >
            {button}
          </Link>
        </Title>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query($locale: String) {
    file(name: { eq: $locale }, relativeDirectory: { eq: "notfound" }) {
      childNotfound {
        title
        subtitle
        button
      }
    }
  }
`

export default NotFoundPage
